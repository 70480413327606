import React, {useEffect, useState} from "react";

export const LatestVideos = ({maxVids = 20, style = "full"}) => {
    const [videos, setVideos] = useState([]);

    function decodeHtmlEntities(str) {
        var textArea = document.createElement("textarea");
        textArea.innerHTML = str;
        return textArea.value;
    }

    async function fetchVideos() {
        try {
            const res = await fetch(
                `${process.env.API_URL}/videos/${maxVids}/0`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.API_KEY,
                    },
                }
            );
            if (res.status === 200) {
                const data = await res.json();
                const lv = [];
                data.videos.forEach((video) => {
                    let title = decodeHtmlEntities(video.item.snippet.title);
                    let media = video.item.snippet.thumbnails.default.url;
                    let bigmedia = video.item.snippet.thumbnails.high.url;
                    const dateObject = new Date(video.item.snippet.publishedAt);
                    let dte = dateObject.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "long",
                    });
                    let link = "/videos?videoId=" + video.item.id.videoId;
                    lv.push({
                        title: title,
                        media: media,
                        bigmedia: bigmedia,
                        date: dte,
                        link: link,
                    });
                });
                setVideos(lv);
            } else {
                //console.error('Failed to fetch scorecard:', res.status);
            }
        } catch (error) {
            //console.log(error);
            //console.error('Network error:', error);
        }
    }

    useEffect(() => {
        fetchVideos();
    }, []);

    return (
        <>
            {style === "summary" ? (
                <>
                    {videos.length > 0 ? (
                        <div
                            className="w-full grid grid-cols-2 gap-2  lg:flex lg:flex-row
        lg:justify-start lg:items-center lg:gap-4"
                        >
                            {videos.map((video, index) => {
                                return (
                                    <div key={index} className="w-full h-full lg:h-[205px]">
                                        <a href={video.link}>
                                            <div
                                                className="w-full h-[30px]
                flex flex-row justify-between items-center bg-mainB"
                                            >
                                                <div
                                                    className="bg-lime font-anton flex justify-center items-center
                  text-main w-[50px] h-[30px] text-[14px] leading-[15px]"
                                                >
                                                    VIDEO
                                                </div>
                                                <div
                                                    className="bg-opacity-15
                  font-chakra normal-case text-white text-right text-[12px] mr-2"
                                                >
                                                    {video.date}
                                                </div>
                                            </div>
                                            <div className="relative">
                                                <img
                                                    src={video.bigmedia}
                                                    className="w-full h-full"
                                                    loading="lazy"
                                                />
                                                <div
                                                    className="bg-white h-[40px] w-[40px] text-[12px] flex items-center justify-center text-[#1b062f] pl-[2px]
                              absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="3em"
                                                        height="3em"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path fill="currentColor" d="M8 5.14v14l11-7z"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className="mt-2 font-anton text-[15px] lg:text-[18px]">
                                                {video.title}
                                                {/* <div className='absolute bottom-0 right-0 bg-lime h-[40px] w-[40px] border-l border-t border-solid border-main pt-[10px] pl-[10px]'>
                    <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                  </div> */}
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </>
            ) : (
                <div className="md:flex md:flex-wrap gap-4">
                    {videos.slice(0, maxVids).map((video, index) => (
                        <div className="w-full md:w-[49%] relative">
                            <a href={video.link}>
                                <img
                                    src={video.media}
                                    className="w-[100%] h-[350px] object-cover"
                                    loading="lazy"
                                />
                                <div
                                    className="absolute bottom-0 md:top-0 left-0 w-full h-full flex flex-col justify-between">
                                    <div
                                        className="absolute bottom-[31px] md:top-0 left-0 w-[50px] h-[30px] bg-white anton text-black text-14px pt-[8px] pl-[10px]">
                                        VIDEOS
                                    </div>
                                    <div
                                        className="absolute bottom-[31px] md:top-0 left-[50px] h-[30px] bg-main anton text-white text-14px pt-[8px] pl-[10px] pr-[10px]">
                                        {video.date}
                                    </div>
                                    <div
                                        className="absolute w-[90%] h-[30px] md:h-[38px] bottom-[1px] md:top-[30px] text-[16px] md:text-[20px] left-0 text-black p-2 bg-lime truncate">
                                        {video.title}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
export default LatestVideos;
